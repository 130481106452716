.waves2 {
  transform: rotate(180deg);
  fill: #0E153A;
  margin-bottom: -10px;
}

.projects {
  background-color: #0e153a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
}

.projects h1 {
  color: white;
}
.projects span {
  border: 1px solid white;
  min-width: 600px;
}

/* Individual Projects ///////////////////////////////////// */
.project {
  width: 600px;
  max-height: 400px;
  min-height: 300px;
  background-color: white;
  border-radius: 20px;
  margin: 100px;
  display: flex;
  flex-direction: row;
}

.projectContent {
  max-width: 300px;
  margin-left: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}

.fas {
  padding-left: 5px;
  font-size: 0.8rem;
}

.projectContent h4 {
  font-size: 1.5rem;
}

.projectContent div {
  margin: 20px 0 20px 0;
  text-align: justify;
}

.siteBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 250px;
}

.projectContent button {
  background-color: red;
  color: white;
  padding: 10px;
  margin: 10px 10px 0 10px;
  border-radius: 10px;
  min-width: 100px;
  border: none;
}

.projectImg {
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
  opacity: 0.75;
  width: 340px;
  height: 360px;
  object-fit: cover;
  margin-left: 0px;
  margin-top: -1px;
  border-radius: 15px 0 0 15px;
}


/* Media Queries */

@media only screen and (max-width: 950px) {
  .projectContent h4 {
      font-size: 1.5rem;
  }
  
  .projectContent div {
      font-size: 1rem;
  }
}

/* projects  ///////////////////////////////// */

@media (max-width: 640px) {
  .project {
      max-height: 500px;
      max-width: 340px;
      flex-direction: column;
      align-items: center;
      margin: 90px;
  }
  .projectContent {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px 0 25px 15px;
      margin-top: -30px;
  }
  
  .projectContent div {
      width: 100%;
      text-align: center;
  }
  
  .projectImg {
      clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
      max-height: 230px;
      width: 100%;
      border-radius: 15px 15px 0 0;
      /* border: 3px solid red; */
      object-fit: cover;
  }
  .projects span {
    min-width: 300px;
  }
}


  