@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Permanent+Marker&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}
a {
    color: white;
    text-decoration: none;
}

body {
    display: flex;
    flex-direction: column;
    background-color: white;
}

