@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Permanent+Marker&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}
a {
    color: white;
    text-decoration: none;
}

body {
    display: flex;
    flex-direction: column;
    background-color: white;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

nav {
    min-height: 10vh;
    padding: 35px 0;
    color: white;
    background-color: #0F1538;
}

.navlist {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
    padding-right: 10%;
}

.navlist li, .navlist button {
    margin: 10px;
    font-size: 14px;
}
.contact button {
    background: none;
    border: none;
    color: white;          
}

.contact {
    position: relative;
}

.contact ul {
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 10px;
    width:80px ;
    list-style: none;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    transition: all 0.4s ease;
}
.contact button:focus + ul {
    opacity: 1;
    pointer-events: all;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.contact a {
    color: black;
    text-decoration: none;
}

.navlist a {
    text-decoration: none;
}

.navlist li:hover, 
.contact button:hover,
.navlist li a:hover {
    cursor: pointer;
    color: #22d1ee;
}
.hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    max-width: 1100px;
}

section {
    display: flex;
    justify-content: center;
    background-color: #0F1538;
    padding-bottom: 130px;
}

.hero img {
    border-radius: 50%;
    max-width: 450px;
    height: 450px;
    object-fit: cover;
    -webkit-filter: grayscale(60%);
            filter: grayscale(60%);
}

.headline {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
}

.headline h1 {
    font-size: 50px;
    font-weight: 600;
    min-width: 304px;
    color: white;
    padding-bottom: 50px;
}

.headline div {
    color: white;
    padding-bottom: 50px;
    text-align: left;
    width: 85%;
}

.hero-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.button-red {
    min-width: 137px;
    height: 45px;
    font-size: 1.1rem;
    padding: 5px 15px;
    background-color: #F20000;
    border: none;
    border-radius: 15px;
    color: white;
    cursor: pointer;
}

.button-blue {
    margin: 0px 0px 0px 30px;
    min-width: 137px;
    height: 45px;
    font-size: 1.1rem;
    padding: 5px 15px;
    background-color: #1E275C;
    border: none;
    border-radius: 15px;
    color: white;
    cursor: pointer;
}

.waves {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    fill: #0E153A;
}

@media (max-width: 1300px) { 
    .headline {
        align-items:center;
        justify-content: center;
    }
    .headline h1 {
        padding-top: 50px;
    }
    .hero-buttons {
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: center;
    }
    .hero img{ 
        max-width: 500px;
        height: 500px;
    }
    .hero {
        flex-direction: column-reverse;
        align-items:center;
        justify-content: center;
    }
}

@media (max-width: 530px) { 
    .hero img{ 
        max-width: 90vw;
        height: 90vw;
    }
}
.aboutContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 300px;
  margin-bottom: 400px;
}

.infoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 90%;
}

.line {
  display: block;
  border: 1px solid black;
  width: 100%;
  margin-bottom: 14px;
}

.infoSection h1 {
  align-self: start;
}

.infoSection div {
  text-align: justify;
}

.copyEmailBtn {
  align-self: end;
  margin-top: 15px;
  width: 140px;
  height: 40px;
  font-size: 1rem;
  background-color: red;
  border: none;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.infoSection button:active {
  background-color: rgb(179, 0, 0);
}

.photoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  max-height: 300px;
  min-width: 300px;
  position: relative;
}

.photoSection img {
  max-width: 180px;
  border-radius: 200px;
}

.photoSection svg {
  position: absolute;
  z-index: -1;
  top: -70px;
  left: -170px;
}
@media only screen and (max-width: 950px) {
  .aboutContainer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 250px;
    margin-bottom: 250px;
  }
  .photoSection {
    margin-bottom: 220px;
    margin-right: 100px;
  }
}
@media (max-width: 450px) {
  .infoSection button {
    top: 160px;
    left: 0px;
  }
}

.waves2 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  fill: #0E153A;
  margin-bottom: -10px;
}

.projects {
  background-color: #0e153a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
}

.projects h1 {
  color: white;
}
.projects span {
  border: 1px solid white;
  min-width: 600px;
}

/* Individual Projects ///////////////////////////////////// */
.project {
  width: 600px;
  max-height: 400px;
  min-height: 300px;
  background-color: white;
  border-radius: 20px;
  margin: 100px;
  display: flex;
  flex-direction: row;
}

.projectContent {
  max-width: 300px;
  margin-left: -20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}

.fas {
  padding-left: 5px;
  font-size: 0.8rem;
}

.projectContent h4 {
  font-size: 1.5rem;
}

.projectContent div {
  margin: 20px 0 20px 0;
  text-align: justify;
}

.siteBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  width: 250px;
}

.projectContent button {
  background-color: red;
  color: white;
  padding: 10px;
  margin: 10px 10px 0 10px;
  border-radius: 10px;
  min-width: 100px;
  border: none;
}

.projectImg {
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
  opacity: 0.75;
  width: 340px;
  height: 360px;
  object-fit: cover;
  margin-left: 0px;
  margin-top: -1px;
  border-radius: 15px 0 0 15px;
}


/* Media Queries */

@media only screen and (max-width: 950px) {
  .projectContent h4 {
      font-size: 1.5rem;
  }
  
  .projectContent div {
      font-size: 1rem;
  }
}

/* projects  ///////////////////////////////// */

@media (max-width: 640px) {
  .project {
      max-height: 500px;
      max-width: 340px;
      flex-direction: column;
      align-items: center;
      margin: 90px;
  }
  .projectContent {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px 0 25px 15px;
      margin-top: -30px;
  }
  
  .projectContent div {
      width: 100%;
      text-align: center;
  }
  
  .projectImg {
      -webkit-clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
              clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
      max-height: 230px;
      width: 100%;
      border-radius: 15px 15px 0 0;
      /* border: 3px solid red; */
      object-fit: cover;
  }
  .projects span {
    min-width: 300px;
  }
}


  
.modal {
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  width: 90%;
  max-width: 30rem;
  z-index: 10;
  position: fixed;
  top: 20vh;
  left: calc(50% - 15rem);
}

.modal h3 {
  margin-top: 20px;
}

.modalBtn {
  margin: 40px 0px 20px 30px;
  min-width: 137px;
  height: 45px;
  font-size: 1.1rem;
  padding: 5px 15px;
  background-color: #1e275c;
  border: none;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.modalBtn:hover {
  background-color: #33439c;
}

.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

@media (max-width: 500px) {
  .modal {
    left: 20px;
  }
}

.formContainer {
  padding-top: 300px;
  padding-bottom: 200px;
  margin: auto;
  width: 90%;
  max-width: 550px;
}

.contactForm {
  background-color: rgb(255, 255, 255);
  padding: 50px;
  height: 40vh;
  min-height: 500px;
  border-radius: 20px;
  box-shadow: 0px 0px 23.9px rgba(0, 0, 0, 0.053), 0px 0px 68.9px rgba(0, 0, 0, 0.077), 0px 0px 190px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.contactHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formButton {
  cursor: pointer;
  padding: 10px 0px 10px 0px;
  border: none;
  font-size: 1.3rem;
  background-color: #0f1538;
  border-radius: 5px;
  color: white;
  transition: 0.3s;
}

.formButton:hover {
  background-color: #223081;
}

svg {
  position: absolute;
  top: -200px;
  left: 60px;
  z-index: -1;
}

@media (max-width: 650px) {
  svg {
    top: -200px;
    left: -239px;
  }
}

.line {
  border: 1px solid rgb(170, 170, 170);
}
.contactHeader i {
  padding-left: 15px;
  font-size: 1.8rem;
}

.FormControl {
  padding-top: 20px;
  border: 1px solid red;
}

