.aboutContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 300px;
  margin-bottom: 400px;
}

.infoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 90%;
}

.line {
  display: block;
  border: 1px solid black;
  width: 100%;
  margin-bottom: 14px;
}

.infoSection h1 {
  align-self: start;
}

.infoSection div {
  text-align: justify;
}

.copyEmailBtn {
  align-self: end;
  margin-top: 15px;
  width: 140px;
  height: 40px;
  font-size: 1rem;
  background-color: red;
  border: none;
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.infoSection button:active {
  background-color: rgb(179, 0, 0);
}

.photoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  max-height: 300px;
  min-width: 300px;
  position: relative;
}

.photoSection img {
  max-width: 180px;
  border-radius: 200px;
}

.photoSection svg {
  position: absolute;
  z-index: -1;
  top: -70px;
  left: -170px;
}
@media only screen and (max-width: 950px) {
  .aboutContainer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 250px;
    margin-bottom: 250px;
  }
  .photoSection {
    margin-bottom: 220px;
    margin-right: 100px;
  }
}
@media (max-width: 450px) {
  .infoSection button {
    top: 160px;
    left: 0px;
  }
}
