.hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    max-width: 1100px;
}

section {
    display: flex;
    justify-content: center;
    background-color: #0F1538;
    padding-bottom: 130px;
}

.hero img {
    border-radius: 50%;
    max-width: 450px;
    height: 450px;
    object-fit: cover;
    filter: grayscale(60%);
}

.headline {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
}

.headline h1 {
    font-size: 50px;
    font-weight: 600;
    min-width: 304px;
    color: white;
    padding-bottom: 50px;
}

.headline div {
    color: white;
    padding-bottom: 50px;
    text-align: left;
    width: 85%;
}

.hero-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.button-red {
    min-width: 137px;
    height: 45px;
    font-size: 1.1rem;
    padding: 5px 15px;
    background-color: #F20000;
    border: none;
    border-radius: 15px;
    color: white;
    cursor: pointer;
}

.button-blue {
    margin: 0px 0px 0px 30px;
    min-width: 137px;
    height: 45px;
    font-size: 1.1rem;
    padding: 5px 15px;
    background-color: #1E275C;
    border: none;
    border-radius: 15px;
    color: white;
    cursor: pointer;
}

.waves {
    transform: rotate(0deg);
    fill: #0E153A;
}

@media (max-width: 1300px) { 
    .headline {
        align-items:center;
        justify-content: center;
    }
    .headline h1 {
        padding-top: 50px;
    }
    .hero-buttons {
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: center;
    }
    .hero img{ 
        max-width: 500px;
        height: 500px;
    }
    .hero {
        flex-direction: column-reverse;
        align-items:center;
        justify-content: center;
    }
}

@media (max-width: 530px) { 
    .hero img{ 
        max-width: 90vw;
        height: 90vw;
    }
}